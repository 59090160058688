import React, { useLayoutEffect, useRef } from 'react';
import { useRouter } from '../../components';
import { SiteLink } from '../../components';
import { ReactComponent as YourSVG } from "./back-base_menu_final-2560px-no-drop-shadow2.svg";
// import BottomDrawer from '../../components/bottomDrawer';

export function BottomDock(props) {
  const links = [
    { url: '/custom', title: 'CUSTOM', icon: 'products-green.png', iconHover: 'products-white.png' },
    { url: '/virtual-store', title: 'VIRTUAL STORE', icon: 'warranty-green.png', iconHover: 'warranty-white.png' },
    { url: '/online-portal', title: 'ONLINE PORTAL', icon: 'recipes-green.png', iconHover: 'recipes-white.png' },
    { url: '/about', title: 'ABOUT', icon: 'contact-green.png', iconHover: 'contact-white.png' },
    { url: '/contact', title: 'CONTACT', icon: 'contact-green.png', iconHover: 'contact-white.png' }
  ];

  //const path = usePath();
  const { pathname } = useRouter();

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  }

  const linkElts = links.map((link, index) =>
    <li key={index} className={`bottomMenu__li ${isCurrent(link.url) ? 'selected' : ''}`}>
      <SiteLink to={link.url} className={`bottomMenu__link bottomMenu__link--${index}`}>
        <div className="icon">
          <img className="normal" src={`/assets/navicons/${link.icon}`} alt={link.title} />
          <img className="selected" src={`/assets/navicons/${link.iconHover}`} alt={link.title} />
        </div>
        <div>{link.title}</div>
      </SiteLink>
    </li>
  );

  // const bottomMenu = useRef(null);
  // useLayoutEffect(() => {
  //   //console.log('useLayoutEffect');
  //   const slider = new BottomDrawer(bottomMenu.current);
  //   setTimeout(() => slider.init(), 1000)
  // }, [])

  return (
    <>
      <nav className="bottomMenu">
        <ul className="bottomMenu__links">
          {linkElts}
        </ul>
        <YourSVG className='bottom__bg__svg' />
        <div className="dumy__bottom_bg"></div>
      </nav>
    </>
  );
}
